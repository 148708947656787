import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, LinearProgress, TextField, Modal, Box, IconButton, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export default function RoutesPage() {
  const [routes, setRoutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedRouteId, setSelectedRouteId] = useState<number>(0);

  useEffect(() => {
    fetch('/assets/downloads/routes.json')
      .then(response => response.json())
      .then(data => setRoutes(data));
  }, []);

  const handleOpen = (id: number) => {
    setSelectedRouteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container mt-5">
      <h1>Running Routes</h1>
      <TextField
        label="Search"
        variant="outlined"
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
      />
      <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-3 gap-2 flex-wrap mt-3">
        {routes.filter((route: any) => route.name.toLowerCase().includes(searchTerm.toLowerCase())).map((route: any) => (
          <div key={route.id} className='m-0 p-0'>
            <Card>
              <CardContent>
                <Typography variant="h5">{route.name}</Typography>
                <Typography variant="body2">Distance: {route.distance}</Typography>
                <Typography variant="body2">Elevation: {route.elevation_gain}</Typography>
                <Typography variant="body2">Difficulty: {route.difficulty} / 10</Typography>
                <LinearProgress variant="determinate" value={parseInt(route.difficulty) * 10} />
                <Button onClick={() => handleOpen(route.id)}>View Details</Button>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, height: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Button onClick={() => window.open(`https://www.strava.com/routes/${selectedRouteId}`, '_blank')}>View on Strava</Button>
          <iframe src={`https://veloviewer.com/routes/${selectedRouteId}/embed2`} width="100%" height="100%" title="Route Details"></iframe>
        </Box>
      </Modal>
    </div>
  );
}