import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useEffect, useState } from "react";
import { callAPI } from "../utils/API";
import { Link } from 'react-router-dom';

export default function SchedulePage() {
  const [sport, setSport] = useState("");
  const [season, setSeason] = useState(0);
  const [schedule, setSchedule] = useState([]);
  const [seasons, setSeasons] = useState<number[]>([]);

  useEffect(() => {
    callAPI("season").then(({ trackSeason, xcSeason }) => {
      const maxSeason = Math.max(trackSeason, xcSeason);
      setSeasons(Array.from({ length: 10 }, (_, i) => maxSeason - i));
      if (trackSeason > xcSeason) {
        setSport("tfo");
        setSeason(trackSeason);
      } else {
        setSport("xc");
        setSeason(xcSeason);
      }
    });
  }, []);

  useEffect(() => {
    if (!sport || !season) return;
    callAPI(`schedule?sport=${sport}&seasonId=${season}`).then((schedule) => {
      console.log(schedule);
      setSchedule(schedule);
    });
  }, [sport, season]);

  return (
    <div className="container mt-5">
      <h1>Season Schedule</h1>
      <FormControl variant="filled">
        <InputLabel id="sport-label">Sport</InputLabel>
        <Select
          labelId="sport-label"
          value={sport}
          onChange={(event) => setSport(event.target.value)}
        >
          <MenuItem value="xc">Cross Country</MenuItem>
          <MenuItem value="tfo">Track & Field</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="filled">
        <InputLabel id="season-label">Season</InputLabel>
        <Select
          labelId="season-label"
          value={season}
          onChange={(event) => setSeason(Number(event.target.value))}
        >
          {seasons.map((season) => (
            <MenuItem value={season}>{season}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((event: any) => (
            <tr key={event.Id}>
              <td>{new Date(event.StartDate).toDateString()}</td>
              <td>{event.Name}</td>
              <td>{event.Location.Name}</td>
              <td>
                {!!event.CalHasResults &&
                  <Link className="btn btn-primary" to={`/results/${sport}/${event.MeetID}`}>Results</Link>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}