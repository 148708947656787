export default function FinalFormsPage() {
  return (
    <div className="container mt-5">
      <h1>FinalForms</h1>
      <p>Access FinalForms here: <a href="https://bellevue-wa.finalforms.com/" target="_blank" rel="noreferrer">bellevue-wa.finalforms.com</a></p>
      <h4>The Bellevue School District uses FinalForms for athletics registration. Read more <a href="https://bsd405.org/departments/athletics-activities/high-school-athletics/" target="_blank" rel="noreferrer">here</a>.</h4>
      <p>You have two account - A parent account, used for entering your information, selecting activities, and signing - A student account, used for re-signing forms as the student</p>
      <h3>Step 1: Enter your information</h3>
      <p><span className="fw-bold">You'll need to sign in as a parent for this.</span> If you haven't signed in to FinalForms before, create an account <a href="https://bellevue-wa.finalforms.com/parents/reset_password?diction=unconfirmed" target="_blank" rel="noreferrer">here</a>.</p>
      <p>FinalForms requires contact information as well as information about your medical history and a signed physical examination form.</p>
      <p>If you've already entered this information in previous years, it'll still be there. However, you'll still need to re-answer a few questions.</p>
      <h3>Step 2: Sign your forms</h3>
      <p>Once you've completed each form, write the parent name in the signature box as directed. This acts as a digital signature.</p>
      <p><span className="fw-bold">Now, sign in to your student account.</span> Again, if you've never signed in before, you can create an account <a href="https://bellevue-wa.finalforms.com/students/reset_password?diction=unconfirmed" target="_blank" rel="noreferrer">here</a>. In your student account, you need to re-sign each form as the student</p>
      <h3>Step 3: Sign up for XC and pay any fees</h3>
      <p><span className="fw-bold">Go back to your parent account</span> and check that your student is outlined in green on the homepage, with the message "Eligible!". If it is not green, it should tell you why.</p>
      <p>If your student's table row is green, go ahead and click the "+ Add Sports/Activities" link.</p>
      <p>Under "Fall", select "Coed Cross Country." If you'd like, you can also go ahead and sign up for "Coed Track and Field" for the Spring.</p>
      <p>Click "Update Sports/Activities." It should take you back to the homepage.</p>
      <p>Head over to <a href="https://bsd405.org/pay" target="_blank" rel="noreferrer">bsd405.org/pay</a> to check for fees and fines in your account. Sign in with your Student ID # and last name. You'll need to pay for an ASB Card (My School -&gt; Back to School -&gt; ASB Card) and an additional fee for each season (should show up automatically when ready). Fees are waived for students in the free or reduced lunch program.</p>
      <p>Go back to FinalForms and check that your student's name is marked in green and you see Cross Country in the Sports/Activities column. If it is, you're all set!</p>
      <p>If you're having issues or have any questions, check out the <a href="https://bsd405.org/departments/athletics-activities/high-school-athletics/" target="_blank" rel="noreferrer">instructions on the BSD website</a> or contact the Interlake Athletic Director. You can find their contact information <a href="https://bsd405.org/departments/athletics-activities/high-school-athletics/">here</a>.</p>
    </div>
  );
}