export default function BusSchedulePage() {
  return (
    <div className="container mt-5">
      <h1 className="margin d-sm-block d-md-block d-none">Bus Schedule</h1>
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Date</th>
            <th>Number of Passengers</th>
            <th>Destination</th>
            <th>Departs IHS</th>
            <th>Arrival</th>
            <th>Departs GAME</th>
          </tr>
        </thead>
        <tbody>

        </tbody>
      </table>
    </div>
  );
}