import { useEffect, useState } from "react";

export default function PaceCalculator() {
  const [distance, setDistance] = useState(0);
  const [distanceUnits, setDistanceUnits] = useState("Mile");
  const [specialEvent, setSpecialEvent] = useState("special");

  const [timeHours, setTimeHours] = useState(0);
  const [timeMinutes, setTimeMinutes] = useState(0);
  const [timeSeconds, setTimeSeconds] = useState(0);

  const [paceHours, setPaceHours] = useState(0);
  const [paceMinutes, setPaceMinutes] = useState(0);
  const [paceSeconds, setPaceSeconds] = useState(0);
  const [paceUnits, setPaceUnits] = useState("Mile");

  const getDistanceUnitFactor = (units: string) => {
    switch (units) {
      case "Kilometer":
        return 1000;
      case "Mile":
        return 1609.34;
      case "Half Mile":
        return 804.672;
      case "Quarter Mile":
        return 402.336;
      case "Eigth Mile":
        return 200.168;
      case "1500M":
        return 1.5;
      case "800M":
        return 0.8;
      case "400M":
        return 0.4;
      case "200M":
        return 0.2;
      case "Meter":
        return 1;
      case "Yard":
        return 0.9144;
    }
    return 1;
  };

  const calculateDistance = () => {
    // Dist = Time / Pace
    if (!timeHours && !timeMinutes && !timeSeconds && !paceHours && !paceMinutes && !paceSeconds) {
      alert("To calculate distance, enter the time and pace");
    } else {
      const distanceFactor = getDistanceUnitFactor(distanceUnits);
      const paceFactor = getDistanceUnitFactor(paceUnits);
      const time = timeHours * 3600 + timeMinutes * 60 + timeSeconds;
      const pace = paceHours * 3600 + paceMinutes * 60 + paceSeconds;
      const distance = time / pace * paceFactor / distanceFactor;
      setDistance(Math.round(distance * 100) / 100);
    }
  };

  const calculateTime = () => {
    // Time = Dist * Pace
    if (!distance) {
      alert("To calculate time, enter the distance and pace");
    } else {
      const distanceFactor = getDistanceUnitFactor(distanceUnits);
      const paceFactor = getDistanceUnitFactor(paceUnits);
      const pace = paceHours * 3600 + paceMinutes * 60 + paceSeconds;
      const time = distance * distanceFactor * pace / paceFactor;
      setTimeHours(Math.floor(time / 3600));
      setTimeMinutes(Math.floor((time % 3600) / 60));
      setTimeSeconds(Math.floor(time % 60));
    }
  };
  const calculatePace = () => {
    // Pace = Time / Dist
    if (!timeHours && !timeMinutes && !timeSeconds && !distance) {
      alert("To calculate pace, enter the time and distance");
    } else {
      const distanceFactor = getDistanceUnitFactor(distanceUnits);
      const paceFactor = getDistanceUnitFactor(paceUnits);
      const time = timeHours * 3600 + timeMinutes * 60 + timeSeconds;
      const pace = time / distance / distanceFactor * paceFactor;
      setPaceHours(Math.floor(pace / 3600));
      setPaceMinutes(Math.floor((pace % 3600) / 60));
      setPaceSeconds(Math.floor(pace % 60));
    }
  };

  const calculateSplits = () => { };

  const reset = () => {
    setDistance(0);
    setDistanceUnits("Mile");
    setSpecialEvent("special");
    setTimeHours(0);
    setTimeMinutes(0);
    setTimeSeconds(0);
    setPaceHours(0);
    setPaceMinutes(0);
    setPaceSeconds(0);
    setPaceUnits("Mile");
  };

  useEffect(() => {
    // If special event is not "special", set thet distance appropriately
    if (specialEvent === "special") return;
    switch (specialEvent) {
      case "Marathon":
        setDistanceUnits("Mile");
        setDistance(26.2);
        break;
      case "Half-Marathon":
        setDistanceUnits("Mile");
        setDistance(13.1);
        break;
      case "5K":
        setDistanceUnits("Kilometer");
        setDistance(5);
        break;
      case "5M":
        setDistanceUnits("Mile");
        setDistance(5);
        break;
      case "8K":
        setDistanceUnits("Kilometer");
        setDistance(8);
        break;
      case "10K":
        setDistanceUnits("Kilometer");
        setDistance(10);
        break;
      case "15K":
        setDistanceUnits("Kilometer");
        setDistance(15);
        break;
      case "10M":
        setDistanceUnits("Mile");
        setDistance(10);
        break;
      case "20K":
        setDistanceUnits("Kilometer");
        setDistance(20);
        break;
      case "15M":
        setDistanceUnits("Mile");
        setDistance(15);
        break;
      case "25K":
        setDistanceUnits("Kilometer");
        setDistance(25);
        break;
      case "30K":
        setDistanceUnits("Kilometer");
        setDistance(30);
        break;
      case "20M":
        setDistanceUnits("Mile");
        setDistance(20);
        break;
    }
  }, [specialEvent]);

  return (
    <div className="container mt-5">
      <h1>Pace Calculator</h1>
      <div className="col-lg-12">
        <p>
          Calculate your running pace per mile, kilometer, or any distance.
        </p>
      </div>
      <div className="col-lg-12">
        <div className="time-input">
          <p>Distance:</p>
          <input type="number" placeholder="0" maxLength={8} onChange={(e) => setDistance(parseInt(e.target.value))} value={distance} />
          <span> </span>
          <select className="custom-select" onChange={(e) => setDistanceUnits(e.target.value)} value={distanceUnits}>
            <option value="Mile" selected>mi</option>
            <option value="Kilometer">km</option>
            <option value="Meter">m</option>
            <option value="Yard">yd</option>
          </select>
          <span className="text-muted small mr-2 ml-2 mt-2"> OR </span>
          <select className="custom-select wide" onChange={(e) => setSpecialEvent(e.target.value)} value={specialEvent}>
            <option value="special">Pick Event</option>
            <option value="Marathon">Marathon</option>
            <option value="Half-Marathon">Half-Marathon</option>
            <option value="5K">5K</option>
            <option value="5M">5 mi</option>
            <option value="8K">8K</option>
            <option value="10K">10K</option>
            <option value="15K">15K</option>
            <option value="10M">10 mi</option>
            <option value="20K">20K</option>
            <option value="15M">15 mi</option>
            <option value="25K">25K</option>
            <option value="30K">30K</option>
            <option value="20M">20 mi</option>
          </select>
          <button onClick={() => calculateDistance()} className="btn btn-primary">
            Calculate
          </button>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="time-input">
          <p>Time:</p>
          <input type="number" placeholder="HH" value={timeHours} onChange={(e) => setTimeHours(parseInt(e.target.value))} />
          <span>:</span>
          <input type="number" placeholder="MM" min="0" max="59" maxLength={2} value={timeMinutes} onChange={(e) => setTimeMinutes(parseInt(e.target.value))} />
          <span>:</span>
          <input type="number" placeholder="SS" min="0" max="59" maxLength={2} value={timeSeconds} onChange={(e) => setTimeSeconds(parseInt(e.target.value))} />
          <button onClick={() => calculateTime()} className="btn btn-primary">
            Calculate
          </button>
        </div>
      </div>
      <div className="col-lg-12 d-flex">
        <div className="time-input">
          <p>Pace:</p>
          <input type="number" placeholder="HH" value={paceHours} onChange={(e) => setPaceHours(parseInt(e.target.value))} />
          <span>:</span>
          <input type="number" placeholder="MM" min="0" max="59" maxLength={2} value={paceMinutes} onChange={(e) => setPaceMinutes(parseInt(e.target.value))} />
          <span>:</span>
          <input type="number" placeholder="SS" min="0" max="59" maxLength={2} value={paceSeconds} onChange={(e) => setPaceSeconds(parseInt(e.target.value))} />
          <span>/</span>
          <select size={1} className="custom-select" onChange={(e) => setPaceUnits(e.target.value)} value={paceUnits}>
            <option value="Mile" selected>mi</option>
            <option value="Kilometer">km</option>
            <option value="Half Mile">880 yd</option>
            <option value="Quarter Mile">440 yds</option>
            <option value="Eigth Mile">220 yds</option>
            <option value="1500M">1500m</option>
            <option value="800M">800m</option>
            <option value="400M">400m</option>
            <option value="200M">200m</option>
            <option value="Meter">m</option>
            <option value="Yard">yd</option>
          </select>
          <button onClick={() => calculatePace()} className="btn btn-primary">
            Calculate
          </button>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="time-input">
          <button className="btn btn-primary" onClick={() => calculateSplits()}>
            Calculate Splits
          </button>
          <button className="btn btn-secondary" onClick={() => reset()}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}