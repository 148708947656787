import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { callAPI } from "../utils/API";

const DISTANCE_EVENTS = ['1600m', '3200m'];

export default function ResultsPage() {
  const { sport, meetId } = useParams<{ sport: string, meetId: string }>();
  const [meet, setMeet] = useState<any | null>(null);
  const [divResults, setDivResults] = useState<any | null>(null);
  useEffect(() => {
    if (!sport || !meetId) return;
    callAPI(`results/${sport.replace('tfo', 'tf')}/${meetId}`).then(({ meet, events }) => {
      const obj = { meet, ...events };
      console.log(obj);
      setMeet(obj);
    });
  }, [sport, meetId]);
  const selectDiv = (filter: any) => {
    if (!meet?.meet?.jwtMeet) return console.error('No jwtMeet', meet);
    fetch('https://www.athletic.net/api/v1/Meet/GetResultsData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Anettokens': meet.meet.jwtMeet
      },
      body: JSON.stringify(filter)
    })
      .then(response => response.json())
      .then(results => {
        if (results.currentEventValid) {
          setDivResults(results);
        } else {
          console.log(results);
        }
      });
  }
  if (!sport || !meetId) return null;
  if (!meet) return null;
  if (!divResults) {
    return (
      <div className="container mt-5">
        <h1>{meet.meet.meet.Name}</h1>
        <p>{new Date(meet.meet.meet.MeetDate).toLocaleDateString()} - {meet.meet.meet.Location?.Name}</p>
        <table className="table">
          <thead>
            <tr>
              <th>Event</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {meet.meet.xcDivisions && meet.meet.xcDivisions.map((division: any) => (
              <tr key={division.IDMeetDiv}>
                <td>{division.DivName} ({division.Gender})</td>
                <td>
                  <button className="btn btn-primary" onClick={() => selectDiv({ divId: division.IDMeetDiv })}>View Results</button>
                </td>
              </tr>
            ))}
            {meet.events && meet.events.sort((a: any, b: any) => {
              // Prioritize distance events
              if (DISTANCE_EVENTS.includes(a.EventShort)) return -1;
              if (DISTANCE_EVENTS.includes(b.EventShort)) return 1;
              return 0;
            }).map((event: any) => (
              <tr key={event.ID}>
                <td className={DISTANCE_EVENTS.includes(event.EventShort) ? "fw-bold" : ""}>{event.Event} ({event.Gender})</td>
                <td>
                  <button className="btn btn-primary" onClick={() => selectDiv({
                    "gender": event.Gender.toLowerCase(),
                    "divId": event.Entry,
                    "eventShort": event.EventShort,
                    "rawResults": false,
                    "showTips": false
                  })} disabled={!event.Result}>View Results</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (sport === "xc") {
    return (
      <div className="container mt-5">
        <h1>{meet.meet.meet.Name}</h1>
        <button className="btn btn-primary" onClick={() => setDivResults(null)}>Back</button>
        <table className="table">
          <thead>
            <tr>
              <th>Place</th>
              <th>Team</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {divResults.teamScores.map((team: any) => (
              <tr key={team.SchoolID}>
                <td>{team.Place}</td>
                <td>{team.Name}</td>
                <td>{team.Points}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>Place</th>
              <th>Name</th>
              <th>Grade</th>
              <th>School</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {divResults.results.map((result: any) => (
              <tr key={result.IDResult}>
                <td>{result.Place}</td>
                <td>{result.FirstName} {result.LastName}</td>
                <td>{result.Grade}</td>
                <td>{result.SchoolName}</td>
                <td>{result.Result}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="container mt-5">
        <h1>{meet.meet.meet.Name}</h1>
        <button className="btn btn-primary" onClick={() => setDivResults(null)}>Back</button>
        <table className="table">
          <thead>
            <tr>
              <th>Place</th>
              <th>Name</th>
              <th>Grade</th>
              <th>School</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {divResults.results.map((result: any) => (
              <tr key={result.IDResult}>
                <td>{result.Place}</td>
                <td>{result.FirstName} {result.LastName}</td>
                <td>{result.Grade}</td>
                <td>{result.SchoolName}</td>
                <td>{result.Result}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}