import { useEffect, useState } from "react";
import "./HomePage.css";
import { callAPI } from "../utils/API";

export default function HomePage() {
  const [sport, setSport] = useState("");
  const [season, setSeason] = useState(0);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    callAPI("season").then(({trackSeason, xcSeason}) => {
      if (trackSeason > xcSeason) {
        setSport("tfo");
        setSeason(trackSeason);
      } else {
        setSport("xc");
        setSeason(xcSeason);
      }
    });
  }, []);

  useEffect(() => {
    callAPI(`schedule?sport=${sport}&seasonId=${season}`).then((schedule) => {
      setSchedule(schedule);
    });
  }, [sport, season]);

  const nextEvent = schedule.find((event: any) => new Date(event.StartDate) >= new Date());
  const eventHappened = nextEvent ? new Date((nextEvent as any).StartDate) < new Date() : false;

  const sections = [
    {
      title: nextEvent ? (nextEvent as any).Name : "No Events",
      subtitle: eventHappened ? "Recent Events" : "Upcoming Events",
      content: nextEvent ? (new Date((nextEvent as any).StartDate).toLocaleDateString() + " - " + (nextEvent as any).Location.Name) : "Check back later for more events.",
      image: "snow.jpg",
      link: {
        name: "Season Schedule",
        permalink: "/schedule",
      },
    },
    {
      title: "Join our Facebook Group",
      subtitle: "Stay Informed",
      content: "The Interlake Long Distance Facebook group is where coaches, captains, and athletes post announcements, updates, and more.",
      image: "olypeaks.jpg",
      link: {
        name: "Join Now",
        permalink: "https://www.facebook.com/groups/IHSLongDistance",
      },
    },
    {
      title: "Join the Team",
      subtitle: "Ready to",
      content: "",
      image: "saltcreek.jpg",
      link: {
        name: "Sign Up Now",
        permalink: "/finalforms",
      },
    },
    {
      title: "Our Coaches",
      subtitle: "Meet",
      content: "",
      image: "mercer.jpg",
      link: {
        name: "Learn More",
        permalink: "/coaches",
      },
    },
    {
      title: "Explore Running Routes",
      subtitle: "",
      content: "",
      image: "explore.jpg",
      link: {
        name: "Explore",
        permalink: "/routes",
      },
    },
    {
      title: "Photos",
      subtitle: "",
      content: "We are lucky to have had several talented photographers help take photos over the years. Take a trip down memory lane; or find out what you're missing out on.",
      image: "earlymorning.jpg",
      link: {
        name: "Browse Photos",
        permalink: "/photos",
      },
    }
  ];
  return (
    <div className="home-page">
      {sections.map((section) => (
        <div key={section.title} className="section">
          <div className="background" style={
            { backgroundImage: `url(/assets/img/homepage/${section.image})`, backgroundPosition: "center 90%" }
          } />
          <div className="section-inner resize" style={{ height: "600px" }}>
            <div className="inner-left-bottom">
              <h4 className="lead">{section.subtitle}</h4>
              <h2 className="animate shadowed">{section.title}</h2>
              <p className="animate shadowed">{section.content}</p>
              <a className="btn btn-primary animate" href={section.link.permalink}>
                <div className="hover"></div>
                <span className="text">{section.link.name}</span>
              </a>
            </div>
          </div>
        </div>
      ))}
      <div id="footer" className="text-center my-4">
        <p>
          <span>Interlake XC © {new Date().getFullYear()}</span>
        </p>
      </div>
    </div>
  );
};