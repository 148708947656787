export default function WarmUpRoutine() {
  const showPhoto = (index: number) => {
  };
  return (
    <div className="container mt-5">
      <h1>Warm Up Routine</h1>
      <p>Click on each activity to view an image of it. Use the online <a href="/abs/">AbsTimer</a> to time abs and stretches.</p>
      <h4>Part 1: Short warm-up jog</h4>
      <p>Jog for about 5 minutes at a very easy pace.</p>
      <div className="xc-graphic abs text-center img">
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-1 rounded" onClick={() => showPhoto(45)}>Easy Jog</div>
          </div>
        </div>
      </div>
      <h4>Part 2: Stretching</h4>
      <p>Hold each stretch for about 10 breaths (at least 30 seconds). You should feel some slight discomfort, but not pain.</p>
      <p className="text-center">
      </p><div className="xc-graphic stretching text-center img">
        <div className="row">
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-1" onClick={() => showPhoto(0)}>Down the Middle</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-2" onClick={() => showPhoto(1)}>To the Right</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-3" onClick={() => showPhoto(2)}>To the Left</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-4" onClick={() => showPhoto(3)}>Quad Stretch</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-5" onClick={() => showPhoto(4)}>UCLA</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-6" onClick={() => showPhoto(5)}>Butt Stretch</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-7" onClick={() => showPhoto(6)}>Downward Dog</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-8" onClick={() => showPhoto(7)}>Upward Dog</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-9" onClick={() => showPhoto(8)}>Hip Stretch + Arms</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-10" onClick={() => showPhoto(9)}>Child's Pose</div>
          </div>
          <div className="col-md-3 col-sm-6 stretch">
            <div className="p-3 border stretch-11" onClick={() => showPhoto(10)}>Knee Hug</div>
          </div>
        </div>
      </div>
      <p></p>
      <h4>Part 3: Abs</h4>
      <p>Do each exercise for 30 seconds, except Plank. Use the online <a href="/abs/">AbsTimer</a> if you'd like.</p>
      <p className="text-center">
      </p><div className="xc-graphic abs text-center img">
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-1 rounded" onClick={() => showPhoto(12)}>Crunches</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-2 rounded" onClick={() => showPhoto(13)}>Crossovers</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-3 rounded" onClick={() => showPhoto(14)}>Crossovers, other side</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-4 rounded" onClick={() => showPhoto(15)}>Jackknives</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-5 rounded" onClick={() => showPhoto(16)}>Penguins</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-6 rounded" onClick={() => showPhoto(17)}>Jackknives, other side</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-7 rounded" onClick={() => showPhoto(18)}>Circles</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-8 rounded" onClick={() => showPhoto(19)}>Circles, other direction</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-9 rounded" onClick={() => showPhoto(20)}>Bicycles</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border abs-ex-10 rounded" onClick={() => showPhoto(21)}>Plank (60 sec)</div>
          </div>
        </div>
      </div>
      <p></p>
      <h4>Part 4: Seal Stretch &amp; Push-ups</h4>
      <p>Try to do at least 10 perfect pushups, or more if you finish before 5 minutes is up. Use good form.</p>
      <div className="xc-graphic abs text-center img">
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-1 rounded" onClick={() => showPhoto(11)}>Seal Stretch</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-2 rounded" onClick={() => showPhoto(46)}>Push Ups</div>
          </div>
        </div>
      </div>
      <h4>Part 5: Myrtles OR Lunges</h4>
      <p>Alternate daily between myrtles and lunges to prevent your muscles from having too much fatigue. 10 on each side.</p>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <p className="text-center">Myrtles</p>
          <p className="text-center">
          </p><div className="xc-graphic myrtles text-center img">
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-1" onClick={() => showPhoto(23)}>Clamshells</div>
              </div>
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-2" onClick={() => showPhoto(24)}>Leg Lifts (Up, Side, Down)</div>
              </div>
            </div>
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-3" onClick={() => showPhoto(25)}>Circles</div>
              </div>
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-4" onClick={() => showPhoto(26)}>Fire Hydrants</div>
              </div>
            </div>
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-5" onClick={() => showPhoto(27)}>Donkey Whips</div>
              </div>
              <div className="col myrtles-ex">
                <div className="p-3 border myrtles-ex-6" onClick={() => showPhoto(28)}>Scorpions</div>
              </div>
            </div>
          </div>
          <p></p>
        </div>
        <div className="col-md-6 col-sm-12">
          <p className="text-center">Lunges</p>
          <p className="text-center">
          </p><div className="xc-graphic myrtles text-center img">
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border lunges-ex-1" onClick={() => showPhoto(29)}>Forward</div>
              </div>
              <div className="col myrtles-ex">
                <div className="p-3 border lunges-ex-2" onClick={() => showPhoto(30)}>Forward w/ Twist</div>
              </div>
            </div>
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border lunges-ex-3" onClick={() => showPhoto(31)}>Side</div>
              </div>
              <div className="col myrtles-ex">
                <div className="p-3 border lunges-ex-4" onClick={() => showPhoto(32)}>Backward</div>
              </div>
            </div>
            <div className="row">
              <div className="col myrtles-ex">
                <div className="p-3 border lunges-ex-5" onClick={() => showPhoto(33)}>Backward 45</div>
              </div>
            </div>
          </div>
          <p></p>
        </div>
      </div>
      <h4>Part 6: Drills</h4>
      <p>Move about 20 meters for each, except Arms are stationary and Buildups are ~100 meters.</p>
      <p className="text-center">
      </p><div className="xc-graphic abs text-center img">
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-1 rounded" onClick={() => showPhoto(35)}>Arms</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-2 rounded" onClick={() => showPhoto(36)}>Knee Hugs</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-4 rounded" onClick={() => showPhoto(38)}>High Knees</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-5 rounded" onClick={() => showPhoto(39)}>Butt Kicks</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-6 rounded" onClick={() => showPhoto(40)}>Backwards</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-3 rounded" onClick={() => showPhoto(37)}>High Knee Skips</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-7 rounded" onClick={() => showPhoto(41)}>Shaggies/heel walks</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-8 rounded" onClick={() => showPhoto(42)}>Bounds</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-9 rounded" onClick={() => showPhoto(43)}>Qi Stretch</div>
          </div>
        </div>
        <div className="row">
          <div className="col abs-ex">
            <div className="p-3 border drills-10 rounded" onClick={() => showPhoto(44)}>Build-ups</div>
          </div>
        </div>
      </div>
      <p></p>
      <h4>Part 7: Go run</h4>
      <p>If you're at practice, Brad will tell you if you should be doing easy mileage or a workout.</p>
      <h4>Part 8: Stretching (post-run)</h4>
      <p>Scroll back up and do the stretching routine again.</p>
      <h4>Part 9: Rolling, icing</h4>
      <p>It's a good idea to roll out your quads, hamstrings, IT bands, and calves to reduce soreness.</p>
      <p>This can be done with a foam roller, handheld roller, softball, golf ball, laccrosse ball, or anything else that works well for you. You'll want a few of these tools for rolling out different areas, since you can't use a foam roller to roll out the bottom of your feet.</p>
      <h1 id="videos">Videos</h1>
      <iframe width="500" allowFullScreen title="Video" height="400" src="https://www.youtube.com/embed/korxBhGzzJE"></iframe>
      <iframe width="500" allowFullScreen title="Video" height="400" src="https://www.youtube.com/embed/5-2gUAOnrco"></iframe>
      <iframe width="500" allowFullScreen title="Video" height="400" src="https://www.youtube.com/embed/GJo7_MiRLkU"></iframe>
      <iframe width="500" allowFullScreen title="Video" height="400" src="https://www.youtube.com/embed/vsUrhRnSzDo"></iframe>

      <br />
      <h1>Downloads</h1>
      <p>List of warm-up routine, no pictures <a href="/assets/downloads/WarmUp_OnePage.pdf">Download (PDF, 1 page)</a></p>
      <p>List of warm-up routine, with pictures <a className="link disabled" href="#">Download (PDF)</a></p>

    </div>
  );
}