export default function Header() {
  const pages = [
    { name: "FinalForms", path: "/finalforms" },
    { name: "Bus", path: "/bus" },
    { name: "Salt Creek", path: "/saltcreek" },
    { name: "Coaches", path: "/coaches" },
    { name: "Resources", path: "/resources" },
    { name: "Schedule", path: "/schedule" },
  ];
  return (
    <header className="p-3 text-bg-dark">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <img src="/assets/img/logo.png" alt="Interlake Cross Country" height="43" />
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            {pages.map((page) => (
              <li key={page.path}>
                <a href={page.path} className="nav-link px-2 text-white">{page.name}</a>
              </li>
            ))}
          </ul>

          <div className="text-end">
            {/* <button type="button" className="btn btn-outline-light me-2">Login</button>
            <button type="button" className="btn btn-warning">Sign-up</button> */}
          </div>
        </div>
      </div>
    </header>
  );
}