import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./content/HomePage";
import ErrorPage from "./content/ErrorPage";
import Header from "./content/Header";
import CoachesPage from "./content/Coaches";
import FinalFormsPage from "./content/FinalFormsPage";
import SaltCreekPage from "./content/SaltCreekPage";
import RoutesPage from "./content/RoutesPage";
import ResultsPage from "./content/ResultsPage";
import BusSchedulePage from "./content/BusSchedulePage";
import WarmUpRoutine from "./content/WarmUpRoutine";
import PaceCalculator from "./content/PaceCalculator";
import AbsTimer from "./content/AbsTimer";
import ResourcesPage from "./content/ResourcesPage";
import SchedulePage from "./content/SchedulePage";
import PhotosPage from "./content/PhotosPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "coaches",
      element: <CoachesPage />,
    },
    {
      path: "finalforms",
      element: <FinalFormsPage />,
    },
    {
      path: "saltcreek",
      element: <SaltCreekPage />,
    },
    {
      path: "routes",
      element: <RoutesPage />,
    },
    {
      path: "results/:sport/:meetId",
      element: <ResultsPage />,
    },
    {
      path: "bus",
      element: <BusSchedulePage />,
    },
    {
      path: "warm-up",
      element: <WarmUpRoutine />,
    },
    {
      path: "abs",
      element: <AbsTimer />,
    },
    {
      path: "pace-calculator",
      element: <PaceCalculator />,
    },
    {
      path: "resources",
      element: <ResourcesPage />,
    },
    {
      path: "schedule",
      element: <SchedulePage />,
    },
    {
      path: "photos",
      element: <PhotosPage />,
    }
  ]);
  return (
    <div>
      <Header />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
