import { Link } from "react-router-dom";

export default function ResourcesPage() {
  return (
    <div className="container mt-5">
      <h1>Resources</h1>
      <ul>
        <li><Link to="/finalforms">FinalForms Instructions</Link></li>
        <li><Link to="/schedule">Season Schedule</Link></li>
        <li><Link to="/routes">Running Routes</Link></li>
        <li><Link to="/warm-up">Warm Up Routine</Link></li>
        <li><Link to="/abs">Abs Routine</Link></li>
        <li><Link to="/pace-calculator">Pace Calculator</Link></li>
        <li><Link to="/photos">Photos</Link></li>
        <li><Link to="/saltcreek">Salt Creek Information</Link></li>
        <li><a href="https://www.athletic.net/team/407/" target="_blank" rel="noreferrer">Athletic.NET</a></li>
        <li><a href="https://www.facebook.com/groups/IHSLongDistance" target="_blank" rel="noreferrer">Facebook Group</a></li>
      </ul>
    </div>
  );
}