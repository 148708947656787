import { useEffect, useState } from "react";
import { callAPI } from "../utils/API";

export default function CoachesPage() {
  const [sport, setSport] = useState("");
  const [season, setSeason] = useState(0);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("coaches")) {
      setCoaches(JSON.parse(localStorage.getItem("coaches") as string));
    } else {
      callAPI("season").then(({ trackSeason, xcSeason }) => {
        if (trackSeason > xcSeason) {
          setSport("tfo");
          setSeason(trackSeason);
        } else {
          setSport("xc");
          setSeason(xcSeason);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!sport || !season) return;
    callAPI(`coaches?sport=${sport}&seasonId=${season}`).then((coaches) => {
      localStorage.setItem("coaches", JSON.stringify(coaches));
      setCoaches(coaches);
    });
  }, [sport, season]);

  const coachesToRender = coaches.length > 0 ? coaches.map((coach: any) => {
    const firstName = coach.Name.split(" ")[0];
    const lastName = coach.Name.split(" ")[1];
    const predictedEmail = `${lastName.toLowerCase()}${firstName[0].toLowerCase()}@bsd405.org`;
    return {
      name: coach.Name,
      position: coach.Position,
      image: coach.PhotoUrl,
      email: predictedEmail,
    };
  }).filter((coach: any) => {
    // Hack: Remove known non-distance coaches and Meet Directors from the list
    return !["Steve Supple", "Amy Conger", "Mike Hamby"].includes(coach.name) && coach.position !== "Meet Director";
  }) :
    [
      { name: "Brad Barquist", image: "/assets/img/coaches/barquist.jpg", position: "Brad is our boys team head coach. He runs Onrax.", email: "barquistb@bsd405.org" },
      { name: "Andrea Miller", image: "/assets/img/coaches/miller.jpg", position: "Andrea is our girls team head coach.", email: "millera@bsd405.org" },
      { name: "Brad Dupea", image: "/assets/img/coaches/dupea.jpg", position: "Brad is our body fixer, a.k.a. the team trainer.", email: "dupeab@bsd405.org" },
      { name: "John Taves", image: "/assets/img/coaches/taves.jpg", position: "John is the team gofer. He maintains this website, so email him if you have any issues with it. He created XCMeet, a cross country timing system. He owns Pacific Northwest Software and PickAtime.", email: "john.taves@pickatime.com" },
    ];
  return (
    <div className="container mt-5">
      <h1>Coaches</h1>
      {coachesToRender.map((coach) => (
        <div className="d-flex gap-4 my-5" key={coach.name}>
          <img src={coach.image || "/assets/img/coaches/avatar.png"} alt={coach.name} className="img-fluid rounded" style={{ maxWidth: "200px" }} />
          <div>
            <h2>{coach.name}</h2>
            <p>{coach.position}</p>
            <p>Email: <a href={`mailto:${coach.email}`}>{coach.email}</a></p>
          </div>
        </div>
      ))}
    </div>
  );
}