import { useEffect, useState } from "react";

const EXERCISES = [
  {
    name: "Five Minute",
    activities: [
      { name: "Crunches", duration: 30 },
      { name: "Crossovers", duration: 30 },
      { name: "Crossovers #2", duration: 30 },
      { name: "Jackknives", duration: 30 },
      { name: "Penguins", duration: 30 },
      { name: "Jackknives #2", duration: 30 },
      { name: "Circles", duration: 30 },
      { name: "Circles #2", duration: 30 },
      { name: "Bicycles", duration: 30 },
      { name: "Plank", duration: 60 },
    ],
  },
  {
    name: "Ten Minute",
    activities: [
      { name: "Crossovers", duration: 30 },
      { name: "Crossovers #2", duration: 30 },
      { name: "Jackknives", duration: 30 },
      { name: "Side Crunches", duration: 30 },
      { name: "Jackknives #2", duration: 30 },
      { name: "Side Crunches #2", duration: 30 },
      { name: "Penguins", duration: 30 },
      { name: "Russian Twists", duration: 30 },
      { name: "Baby Pushers", duration: 30 },
      { name: "Circles", duration: 30 },
      { name: "Circles #2", duration: 30 },
      { name: "Butt Lifts", duration: 30 },
      { name: "Flutters", duration: 30 },
      { name: "Leg Lifts", duration: 30 },
      { name: "Bicycles", duration: 30 },
      { name: "Side Plank", duration: 30 },
      { name: "Side Plank #2", duration: 30 },
      { name: "Plank", duration: 60 },
    ],
  },
  {
    name: "Plank",
    activities: [
      { name: "Plank", duration: 60 },
      { name: "Side Plank", duration: 60 },
      { name: "Side Plank #2", duration: 60 },
      { name: "High Plank", duration: 60 },
      { name: "Shoulder Taps", duration: 60 },
    ],
  },
  {
    name: "Myrtles",
    activities: [
      { name: "Clamshells", duration: 0 },
      { name: "Leg Lifts", duration: 0 },
      { name: "Circles", duration: 0 },
      { name: "Fire Hydrants", duration: 0 },
      { name: "Donkey Whips", duration: 0 },
      { name: "Scorpions", duration: 0 },
    ],
  },
  {
    name: "Stretching",
    activities: [
      { name: "Down the Middle", duration: 30 },
      { name: "To the Right", duration: 30 },
      { name: "To the Left", duration: 30 },
      { name: "Quad Stretch", duration: 30 },
      { name: "Quad Stretch #2", duration: 30 },
      { name: "UCLA", duration: 30 },
      { name: "UCLA #2", duration: 30 },
      { name: "Knee Hug", duration: 30 },
      { name: "Drop it to the Side", duration: 30 },
      { name: "Knee Hug", duration: 30 },
      { name: "Drop it to the Side #2", duration: 30 },
      { name: "Butt Stretch", duration: 30 },
      { name: "Butt Stretch #2", duration: 30 },
      { name: "Downward Dog", duration: 30 },
      { name: "Upward Dog", duration: 30 },
      { name: "Hip Stretch", duration: 30 },
      { name: "Hip Stretch #2", duration: 30 },
      { name: "Child's Pose", duration: 30 },
    ],
  }
];

export default function AbsTimer() {
  const [currentExercise, setCurrentExercise] = useState(EXERCISES[0]);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [remainingTime, setRemainingTime] = useState(currentExercise.activities[0].duration);
  const [timerId, setTimerId] = useState<number | null>(null);

  const startTimer = () => {
    if (timerId !== null) return;
    const id = window.setInterval(() => {
      setRemainingTime(time => {
        if (time === 0) {
          // Move to the next activity or stop the timer if there are no more activities
          const nextActivityIndex = currentActivityIndex + 1;
          if (nextActivityIndex < currentExercise.activities.length) {
            setCurrentActivityIndex(nextActivityIndex);
            return currentExercise.activities[nextActivityIndex].duration;
          } else {
            clearInterval(id);
            setTimerId(null);
            return 0;
          }
        } else {
          return time - 1;
        }
      });
    }, 1000);
    setTimerId(id);
  };

  const stopTimer = () => {
    if (timerId === null) return;
    clearInterval(timerId);
    setTimerId(null);
  };

  const skip = () => {
    const nextActivityIndex = currentActivityIndex + 1;
    if (nextActivityIndex < currentExercise.activities.length) {
      setCurrentActivityIndex(nextActivityIndex);
      setRemainingTime(currentExercise.activities[nextActivityIndex].duration);
    } else {
      stopTimer();
    }
  };

  const resetTimer = () => {
    setCurrentActivityIndex(0);
    setRemainingTime(currentExercise.activities[0].duration);
  };

  const changeAbs = (event: any) => {
    const newExercise = EXERCISES.find(exercise => exercise.name === event.target.value);
    if (!newExercise) return;
    setCurrentExercise(newExercise);
    resetTimer();
  };

  useEffect(() => {
    resetTimer();
  }, [currentExercise]);
  return (
    <div className="container mt-5">
      <h1>
        <img height="35pt" className="m-2" src="https://services.garmin.com/appsLibraryBusinessServices_v0/rest/apps/851d7855-fcfe-45c6-86bb-a9488398ffdf/icon/9f3b889f-1b78-4ba9-bfb4-0cf6ce5640ae" />
        AbsTimer
      </h1>
      <div className="timer">
        <div className="text text-center">
          <p className="exercize" id="the-name">Waiting to Start...</p>
          <p className="time" id="the-time">0.00</p>
        </div>
      </div>
      <div style={{ display: "F" }} className="stopwatch"></div>
      <button className="btn btn-primary" id="start-timer" onClick={() => startTimer()}>Start</button>
      <button className="btn btn-primary" id="stop-timer" onClick={() => stopTimer()}>Stop</button>
      <button className="btn btn-primary" id="skip-timer" onClick={() => skip()} disabled>Skip</button>
      <button className="btn btn-primary" id="reset-timer" onClick={() => resetTimer()}>Reset</button>
      <select id="abs-type" className="form-select" onChange={(event) => changeAbs(event)}>
        {EXERCISES.map((exercise) => {
          const totalDurationSeconds = exercise.activities.reduce((acc, activity) => acc + activity.duration, 0);
          const totalDurationMinutes = Math.floor(totalDurationSeconds / 60);
          const totalDurationSecondsLeft = totalDurationSeconds % 60;
          return (
            <option key={exercise.name} value={exercise.name}>{exercise.name} ({totalDurationMinutes}:{totalDurationSecondsLeft.toString().padStart(2, "0")})</option>
          );
        })}
      </select>
      <p className="text-small text-muted">Get it on the <a href="https://apps.garmin.com/en-US/apps/851d7855-fcfe-45c6-86bb-a9488398ffdf" target="_blank" rel="noreferrer">Garmin Connect IQ Store</a>.</p>
    </div>
  );
}