export default function SaltCreekPage() {
  return (
    <div className="container mt-5">
      <h1>Salt Creek</h1>
      <div className="row">
        <div className="col-12">
          <h1 className="margin d-sm-block d-md-block d-none text-center">Salt Creek Invitational</h1><br />
          <p>Salt Creek is an overnight trip and a team tradition. It is super fun. The team travels to Port Angeles September 20th and stays until Saturday the 21st, to compete in the Salt Creek Invitational meet on Saturday morning.</p>
        </div>
        <div className="col-sm-12 col-md-6">
          <ul className="list">
            <li className="list-item">We leave Interlake at 11:30am on the 20th</li>
            <li className="list-item">We arrive back at Interlake about 6pm on the 21st</li>
            <li className="list-item">See full itinterary below</li>
          </ul>
          <h3>Cost</h3>
          <p>The cost of the trip is $85 and is billed to your runner's school account. This covers all transportation and meals. It's fine (but not necessary) to send your runner with additional $ to cover things like snacks on the ferry or a meet T-Shirt.</p>
          <h3>Lodging</h3>
          <p>Your runner's hotel room is already booked at the Red Lion. If you are a parent driver or parent attendee, please book your room directly with the Red Lion Inn. Phone is 360-452-9215. We have 10 rooms on hold for parent volunteers. Indicate that you are with Interlake to get the discounted room rate.</p>
          <h3>Forms</h3>
          <ul className="list">
            <li className="list-item">Required: <a href="/assets/downloads/ParentAuth.pdf">Parent Authorization</a><br />This is BSD's "Parent Authorization and Acknowledgment of RIsk for Out of State or Overnight Field Trip" form.</li>
            <li className="list-item">Required: <a href="/assets/downloads/Overnight.pdf">Overnight Authorization</a><br />This is BSD's "Out of State or Overnight Field Trip Emergency Health Form."</li>
            <li className="list-item">Optional: <a href="/assets/downloads/DriverAuth.pdf">Driver Authorization</a><br />We need parent drivers for Salt Creek!! Please help by volunteering to drive. Complete this form and submit to <a href="mailto:higginsk@bsd405.org">higginsk@bsd405.org</a> (not coaches).</li>
            <li className="list-item">Optional: <a href="/assets/downloads/MedAuth.pdf">Medication Authorization</a><br />All prescription or over the counter medications administered on this trip require this form. Please complete if this applies to your runner.</li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-6">
          <h1>Itinerary</h1>
          <p>Friday</p>
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">11:10am</th>
                <td>Parent Drivers Arrive at Interlake</td>
              </tr>
              <tr>
                <th scope="row">11:15am</th>
                <td>Students Dismissed from Class</td>
              </tr>
              <tr>
                <th scope="row">11:30am</th>
                <td>Leave Interlake</td>
              </tr>
              <tr>
                <th scope="row">12:40pm</th>
                <td>Be on the Edmunds Ferry</td>
              </tr>
              <tr>
                <th scope="row">2:30pm</th>
                <td>Arrive At Hotel and Check In</td>
              </tr>
              <tr>
                <th scope="row">2:45pm</th>
                <td>Students Leave for Practice at Salt Creek Park</td>
              </tr>
              <tr>
                <th scope="row">3-5pm</th>
                <td>Students Run Course</td>
              </tr>
              <tr>
                <th scope="row">5:30pm</th>
                <td>Return to Hotel</td>
              </tr>
              <tr>
                <th scope="row">6pm</th>
                <td>Dinner</td>
              </tr>
              <tr>
                <th scope="row">8pm</th>
                <td>Team Meeting</td>
              </tr>
              <tr>
                <th scope="row">10pm</th>
                <td>Lighte Out</td>
              </tr>
            </tbody>
          </table>
          <p>Saturday</p>
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">7am</th>
                <td>Morning Team Run</td>
              </tr>
              <tr>
                <th scope="row">7:15am</th>
                <td>Breakfast at the Hotel</td>
              </tr>
              <tr>
                <th scope="row">7:45am</th>
                <td>Leave For Meet</td>
              </tr>
              <tr>
                <th scope="row">2pm</th>
                <td>Meet Ends, Return to School</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}